import React, { useState, useCallback } from 'react';
import Collapsible from 'react-collapsible';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { useStyles, useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useStylesParams, useSettingsParams } from '../../../../../hooks';
import { useShouldUseMobileSettings } from '../../../../../hooks/useShouldUseMobileSettings';
import { BenefitBulletType } from '../../../../../types/common';
import { alignmentName } from '../../../../../utils/alignment';
import { iconsMap } from '../../../commons/bulletIcons';
import { CollapsibleState } from './CollapsibleState';
import { st, classes } from './Footer.st.css';

interface FooterProps {
  benefits: string[];
  highlighted: boolean;
  anyBenefits?: boolean;
  expandMobileBenefits: boolean;
}

export const Footer: React.FC<FooterProps> = ({ benefits, anyBenefits, highlighted, expandMobileBenefits }) => {
  const { isMobile } = useEnvironment();
  const stylesParams = useStylesParams();
  const styles = useStyles();
  const showPlanBenefits = styles.get(stylesParams.showBenefits);

  if (!showPlanBenefits) {
    return null;
  }
  if (isMobile) {
    return benefits.length > 0 ? (
      <MobileBenefits expandMobileBenefits={expandMobileBenefits} benefits={benefits} highlighted={highlighted} />
    ) : null;
  } else {
    return anyBenefits ? <Benefits benefits={benefits} mobile={false} highlighted={highlighted} /> : null;
  }
};

interface BenefitsProps {
  benefits: string[];
  highlighted: boolean;
  mobile: boolean;
}

const Benefits: React.FC<BenefitsProps> = React.memo(({ benefits, highlighted, mobile }) => {
  const stylesParams = useStylesParams();
  const styles = useStyles();
  const useMobileSettings = useShouldUseMobileSettings();

  const benefitsAlignment = alignmentName(styles.get(stylesParams.benefitsAlignment));

  return (
    <ul
      data-hook={VIEWER_HOOKS.BENEFITS}
      className={st(classes.benefits, {
        highlighted,
        mobile,
        useMobileSettings,
        textAlignment: benefitsAlignment,
      })}
    >
      {benefits.map((benefit, i) => (
        <li data-hook={VIEWER_HOOKS.BENEFIT} key={i}>
          <Benefit highlighted={highlighted}>{benefit}</Benefit>
        </li>
      ))}
    </ul>
  );
});

const flex1 = { flex: 1 };

interface MobileBenefitsProps {
  benefits: string[];
  highlighted: boolean;
  expandMobileBenefits: boolean;
}

const MobileBenefits: React.FC<MobileBenefitsProps> = React.memo(({ benefits, highlighted, expandMobileBenefits }) => {
  const [expanded, setExpanded] = useState<boolean>(highlighted);
  const toggleExpanded = useCallback(() => setExpanded(!expanded), [setExpanded, expanded]);
  const isOpen = expanded || expandMobileBenefits;

  return (
    <div onClick={toggleExpanded} style={flex1}>
      <Collapsible trigger="" triggerStyle={{ display: 'none' }} open={isOpen} easing="ease-out">
        <Benefits mobile benefits={benefits} highlighted={highlighted} />
      </Collapsible>
      <CollapsibleState
        dataHook={VIEWER_HOOKS.EXPAND_BENEFITS}
        className={st(classes.arrow, { expanded: isOpen, highlighted })}
        open={isOpen}
      />
    </div>
  );
});

const Benefit: React.FC<Pick<BenefitsProps, 'highlighted'>> = ({ children, highlighted }) => {
  const settings = useSettings();
  const styles = useStyles();
  const settingsParams = useSettingsParams();
  const stylesParams = useStylesParams();
  const { isRTL, isMobile } = useEnvironment();
  const useMobileSettings = useShouldUseMobileSettings();

  const iconProps = () => {
    const lineHeight = 1.5;
    const defaultBenfitFontSize = 14;
    const fontSize =
      isMobile && useMobileSettings
        ? styles.get(stylesParams.planBenefitsMobileFontSize)
        : styles.get(stylesParams.planBenefitsFont).size;

    return {
      size: (fontSize ?? defaultBenfitFontSize) * lineHeight,
      flipped: isRTL,
      className: st(classes.bulletIcon, { highlighted }),
    };
  };

  const icon = () => {
    const bulletType = settings.get(settingsParams.benefitsBullet);
    if (bulletType === BenefitBulletType.NONE) {
      return null;
    } else {
      const IconComponent = iconsMap[bulletType];
      return <IconComponent {...iconProps()} />;
    }
  };
  return (
    <>
      {icon()}
      {children}
    </>
  );
};
